import PropTypes from 'prop-types';
import { groupBy, map } from 'underscore';
import { ExpandableList } from 'bv-components';

import Meeting from './meeting';

const groupedMeetings = (meetings) => map(
  groupBy(meetings, 'group'),
  (groupMeetings, groupName) => ({ meetings: groupMeetings, groupName }),
);

const filterMeetings = (meetings, excluded, included) => (
  meetings
    .filter((meeting) => !excluded.includes(meeting.id))
    .filter((meeting) => (included.length === 0 ? true : included.includes(meeting.id)))
);

const Meetings = (props) => groupedMeetings(
  filterMeetings(
    props.meetings,
    props.excludedMeetingIds,
    props.selectedMeetingIds,
  ),
).map((group, index) => (
  <ExpandableList header={group.groupName} expanded={index < props.nExpanded} key={group.groupName}>
    {group.meetings.map((meeting) => (
      <Meeting {...meeting} sportId={meeting.sport_id} key={meeting.id} />
    ))}
  </ExpandableList>
));

Meetings.propTypes = {
  meetings: PropTypes.arrayOf(PropTypes.shape).isRequired,
  nExpanded: PropTypes.number,
  excludedMeetingIds: PropTypes.arrayOf(PropTypes.number),
  selectedMeetingIds: PropTypes.arrayOf(PropTypes.number),
};

Meetings.defaultProps = {
  nExpanded: 2,
  selectedMeetingIds: [],
  excludedMeetingIds: [],
};

export default Meetings;
