import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { t } from 'bv-i18n';
import { Spinner } from 'bv-components';
import { withScrollToTop } from 'bv-hocs';
import {
  fetchSport as fetchSportThunk,
  resetState as resetStateThunk,
} from '../duck';
import Content from './content';

class App extends PureComponent {
  componentDidMount() {
    const { fetchSport, sportId } = this.props;
    fetchSport(sportId);
  }

  componentWillUnmount() {
    const { resetState } = this.props;
    resetState();
  }

  render() {
    const {
      fetched, sportId, fetchedSportId, sections, activeSectionLabel,
    } = this.props;
    // I need to do this to make sure that when sportId the old data is not shown
    // Because otherwise the backbone view would not be removed
    // (would have to call Page::Unrender again)
    // TODO: Cleanup once home components are in react
    if (fetched && sportId === fetchedSportId) {
      return sections.length
        ? (
          <Content
            sections={sections}
            activeSectionLabel={activeSectionLabel}
          />
        )
        : (
          <div className="no-events-message" data-track="no-markets-sport">
            <div className="no-events-message__icon" />
            <div className="no-events-message__text">
              <p>{t('javascript.in_play.no_sports')}</p>
            </div>
          </div>
        );
    }

    return <Spinner />;
  }
}

App.propTypes = {
  sportId: PropTypes.number.isRequired,
  resetState: PropTypes.func.isRequired,
  fetchSport: PropTypes.func.isRequired,
  fetched: PropTypes.bool.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeSectionLabel: PropTypes.string.isRequired,
  fetchedSportId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({ ...state.sportHome });

const mapDispatchToProps = (dispatch) => ({
  fetchSport: compose(dispatch, fetchSportThunk),
  resetState: compose(dispatch, resetStateThunk),
});

export default withScrollToTop()(connect(mapStateToProps, mapDispatchToProps)(App));
