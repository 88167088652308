import { guard } from 'bv-services/features';
import { compact, property } from 'underscore';

// ===================================================
// Filter out not enabled components (feature flipper)
// ===================================================
const componentFilters = {
  // Home components can be confusing, it is a component of components
  // We check that some of its components (featured) is enabled
  home_components: (component) => (
    Promise.all(
      component.featured
        .map(property('featureFlipperKey'))
        .map((key) => guard(key)),
    ).then((enabledArray) => {
      const featured = component.featured.filter(
        (feature, index) => !feature.featureFlipperKey || enabledArray[index],
      );

      if (featured.length) {
        return {
          ...component,
          featured,
        };
      }

      return null;
    })
  ),
};

const checkComponent = (component) => (
  componentFilters[component.type] ? componentFilters[component.type](component) : component
);

const filterComponentsInSection = (section) => (
  Promise.all(section.components.map(checkComponent))
    .then(compact)
    .then((components) => ({
      ...section,
      components,
    }))
);

export const filterEnabledComponents = (sport) => (
  Promise.all(sport.sections.map(filterComponentsInSection)).then((sections) => ({
    ...sport,
    sections,
  }))
);

// ======================================
// Filter out sections without components
// ======================================
const notEmptySection = (section) => section.components.length;

export const filterNotEmptySections = (sport) => {
  const sections = sport.sections.filter(notEmptySection);
  return {
    ...sport,
    sections,
  };
};
