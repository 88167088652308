import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import {
  ExpandableList, List, ListLinks, ListRow,
} from 'bv-components';

const listComponentFor = (linkGroup) => [
  {
    condition: () => !linkGroup.showTitle,
    component: ListLinks,
  },
  {
    condition: () => linkGroup.showTitle && linkGroup.collapsible,
    component: ExpandableList,
  },
  {
    condition: () => true,
    component: List,
  },
].find((element) => element.condition()).component;

const CustomListComponent = ({ id, linkGroups, sportEventPathId }) => (
  linkGroups.length
    ? (
      <>
        {
      linkGroups.map((linkGroup, linkGroupIndex) => {
        const ListComponent = listComponentFor(linkGroup);

        return (
          <ListComponent header={linkGroup.title} expanded={linkGroup.expandedByDefault}>
            {
              linkGroup.links.map((link, linkIndex) => (
                <ListRow
                  to={`/sports/${sportEventPathId}/sections/custom-list/${id}/group/${linkGroup.slug || linkGroupIndex}/item/${link.slug || linkIndex}`}
                  label={link.description}
                />
              ))
            }
          </ListComponent>
        );
      })
    }
      </>
    )
    : (
      <div className="no-events-message" data-track="no-markets-list">
        <div className="no-events-message__icon" />
        <div className="no-events-message__text">
          <p>{t('javascript.in_play.no_custom_list')}</p>
        </div>
      </div>
    )
);

CustomListComponent.propTypes = {
  id: PropTypes.number.isRequired,
  linkGroups: PropTypes.instanceOf(Array).isRequired,
  sportEventPathId: PropTypes.number.isRequired,
};

export default CustomListComponent;
