import PropTypes from 'prop-types';
import { Button } from 'bv-components';

const SegregationsSelectorView = ({ segregation, active, onClick }) => (
  <Button
    active={active}
    onClick={onClick}
    noClass
    className="bvs-button-chip is-small"
  >
    { segregation.description }
  </Button>
);

SegregationsSelectorView.propTypes = {
  segregation: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SegregationsSelectorView;
