import reducer from './duck';
import AppLayout from './components/app_layout';

const { addReducers } = window.reduxState;

addReducers({
  sportHome: reducer,
});

export default () => (<AppLayout />);
