import PropTypes from 'prop-types';
import Section from './section';

const Sections = ({ sections, activeSection }) => (
  sections.map((section, index) => (
    <Section {...section} active={activeSection === index} />
  ))
);

Sections.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
  activeSection: PropTypes.number.isRequired,
};

export default Sections;
