import { createElement, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { classify } from 'underscore.string';

import * as sectionComponents from './section_components';

const Section = ({ components, active }) => {
  const [wasActiveOnce, setWasActiveOnce] = useState(false);
  const memoizedSectionComponents = useMemo(() => components.map((component) => {
    const componentClass = sectionComponents[classify(component.type)];

    return componentClass ? createElement(componentClass, component) : null;
  }), [components]);

  if (active && !wasActiveOnce) {
    setWasActiveOnce(true);
  }

  if (!wasActiveOnce && !active) return null;

  return (
    <div className="section-wrapper" style={!active ? { display: 'none' } : {}}>
      {memoizedSectionComponents}
    </div>
  );
};

Section.propTypes = {
  active: PropTypes.bool.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Section;
