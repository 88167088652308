import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';

const HomeComponentsLazy = withLazyLoad()('homeComponents');

const HomeComponentsLazyWrapper = ({ featured }) => (
  <HomeComponentsLazy components={featured} />
);

HomeComponentsLazyWrapper.propTypes = {
  featured: PropTypes.instanceOf(Array).isRequired,
};

export default HomeComponentsLazyWrapper;
