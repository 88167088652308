import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonCard, Carousel } from 'bv-components';

const SectionTabs = ({
  sections, activeSection, onTabClick, routerTabIndex,
}) => {
  const elCarousel = useRef();
  useEffect(() => {
    if (routerTabIndex > 3) {
      elCarousel.current.scrollTo((routerTabIndex * 75), 0);
    }
  }, []);

  return (
    <Carousel className="bvs-carousel--embedded sportbook-section-tabs" ref={elCarousel} scrollStep={110}>
      {
      sections.map((section, index) => (
        <ButtonCard
          label={section.label}
          active={activeSection === index}
          onClick={() => onTabClick(index)}
          noClass
          type="sportbook-section-tabs"
          key={section.label}
        />
      ))
    }
    </Carousel>
  );
};

SectionTabs.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
  activeSection: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
  routerTabIndex: PropTypes.number,
};

SectionTabs.defaultProps = {
  routerTabIndex: 0,
};

export default SectionTabs;
