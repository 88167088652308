import { useFeature } from 'bv-hooks';

export default (sections, activeSectionLabel) => {
  /*

    The reason why we're performing the filtering of enabled / disabled
    feature flipper sections in advance is because:

    - We don't want to draw any section until all feture flippers for all
      tabs have been resolved so the user don't see any flickering of tabs
      appearing / dissapearing

    - The carousel needs to know the widht of its childs in order to behave
      correctly

  */
  const processedSections = sections.map((section) => {
    // CASE 1: the section does not have featureFlipperKey or disabledFeatureFlipperKey
    if (!section.featureFlipperKey && !section.disabledFeatureFlipperKey) {
      return { enabled: true, resolved: true, ...section };
    }

    // CASE 2: the section has a featureFlipperKey
    if (section.featureFlipperKey) {
      const [enabled, resolved] = useFeature(section.featureFlipperKey);

      return { enabled, resolved, ...section };
    }

    // CASE 3: the section does not have a featureFlipperKey and has a disabledFeatureFlipperKey
    const [disabled, resolved] = useFeature(section.disabledFeatureFlipperKey);

    return { enabled: !disabled, resolved, ...section };
  });

  // Do not return the Carousel until all sections have been resolved
  if (!processedSections.every((section) => section.resolved)) {
    return {
      allResolved: false,
      enabledSections: [],
    };
  }

  const enabledSections = processedSections.filter((section) => section.enabled);

  const isActiveSectionLabel = (label) => (
    activeSectionLabel && label && label.toUpperCase() === activeSectionLabel.toUpperCase());

  const defaultActiveTabIndex = enabledSections
    .findIndex((section) => (
      (isActiveSectionLabel(section.legacyLabel)
      || !section.legacyLabel) && isActiveSectionLabel(section.label)
    ));

  return {
    allResolved: true,
    enabledSections,
    // TODO: This is a workaround right now because natives are using as well labels
    // to check which is the active section as it is what was configured in the CMS
    // IMO we should be using the sport tab component ids in order to see which is the active
    // one
    defaultActiveTabIndex: Math.max(defaultActiveTabIndex, 0),
  };
};
