import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useEnabledSections } from '../hooks';
import SectionTabs from './section_tabs';
import Sections from './sections';

const Content = ({ sections, activeSectionLabel }) => {
  const {
    allResolved,
    enabledSections,
    defaultActiveTabIndex,
  } = useEnabledSections(sections, activeSectionLabel);

  const history = useHistory();
  const { search } = useLocation();

  if (!allResolved) return null;

  const routerTabIndex = parseInt(new URLSearchParams(search).get('tab'), 10);
  const activeTabIndex = Number.isNaN(Number(routerTabIndex))
    ? defaultActiveTabIndex : routerTabIndex;

  const tabClicked = (tabIndex) => {
    if (activeTabIndex !== tabIndex) history.push({ search: `?tab=${tabIndex}` });
  };

  return (
    <>
      {
        enabledSections.length > 1 && (
        <SectionTabs
          sections={enabledSections}
          activeSection={activeTabIndex}
          onTabClick={tabClicked}
          routerTabIndex={routerTabIndex}
        />
        )
      }
      <Sections
        sections={enabledSections}
        activeSection={activeTabIndex}
      />
    </>
  );
};

Content.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeSectionLabel: PropTypes.string.isRequired,
};

export default Content;
