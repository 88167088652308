import { ListRow } from 'bv-components';

const SegregationView = ({ segregation }) => segregation.children.map((competition) => (
  <ListRow
    href={competition.competitionUrl}
    label={competition.description}
    hasArrow
  />
));

export default SegregationView;
