import PropTypes from 'prop-types';
import { ExpandableList, Icon } from 'bv-components';

import Event from './event';

const Events = ({ sportId, header, eventsTree }) => (
  <div className="events-component">
    <div className="bvs-h5">
      <Icon type="sports-home-events-list-icon" className={`is-sport-${sportId}`} big />
      {header}
    </div>
    {
      eventsTree.map(({ description, children }) => (
        <ExpandableList header={description}>
          {children.map((event) => <Event {...event} />)}
        </ExpandableList>
      ))
    }
  </div>
);

Events.propTypes = {
  eventsTree: PropTypes.arrayOf(PropTypes.shape).isRequired,
  header: PropTypes.string.isRequired,
  sportId: PropTypes.isRequired,
};

export default Events;
