import PropTypes from 'prop-types';
import { SegregationSelector } from './segregation_selector';

const SegregationsSelectorView = ({
  segregations,
  activeSegregation,
  setActiveSegregation,
}) => (
  <li>
    <div className="segregations-selector-wrapper">
      {segregations.map((segregation) => (
        <SegregationSelector
          segregation={segregation}
          active={activeSegregation === segregation.dimension_value}
          onClick={() => setActiveSegregation(segregation.dimension_value)}
          key={segregation.dimension_value}
        />
      ))}
    </div>
  </li>
);

SegregationsSelectorView.propTypes = {
  segregations: PropTypes.instanceOf(Array).isRequired,
  activeSegregation: PropTypes.string.isRequired,
  setActiveSegregation: PropTypes.func.isRequired,
};

export default SegregationsSelectorView;
