import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContentLayout, SeoTitle } from 'bv-components';
import { getSportDescription } from 'bv-services/sports';
import { SEOSportTitle } from 'bv-helpers/tabs';
import { v as bvVar } from 'bv';

import { ErrorPage } from 'Index/features/routing/components/main_routes/lazy_components';
import App from './app';
import SportBanner from './sport_banner';

const AppLayout = () => {
  const { sportId } = useParams();
  const [error404, setError404] = useState(false);

  const getTitlePromise = (id) => () => (
    getSportDescription(parseInt(id, 10)).then((title) => SEOSportTitle(title))
  );

  useEffect(() => {
    getSportDescription(parseInt(sportId, 10)).then((title) => {
      setError404(title === undefined);
    });
  }, [sportId]);

  if (error404) {
    return <ErrorPage showContentLayout />;
  }

  return (
    <ContentLayout>
      {bvVar('sportsBanner') && <SportBanner sportId={sportId} />}
      <SeoTitle defaultHeadingPromise={getTitlePromise(sportId)} />
      <App sportId={sportId} key={sportId} />
    </ContentLayout>
  );
};

export default AppLayout;

// TODO: Key added to App because it fetches on mounted only.
// To be rewriten with hooks and fetch when sportId changes
