import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Spinner } from 'bv-components';
import { Hooks as CompetitionsHooks } from 'sportsbook-competitions';
import { CompetitionsGroup } from './competitions_group';

const SportsTreeContainer = ({ sportDimension }) => {
  const [sportTree, loadingSportTree] = CompetitionsHooks.useCompetitions({
    sportDimension,
  });

  if (loadingSportTree) return <Spinner />;

  return sportTree.length
    ? (
      sportTree.map((competitionsGroup, index) => (
        <CompetitionsGroup
          competitionsGroup={competitionsGroup}
          expanded={index < 2}
        />
      ))
    )
    : (
      <div className="no-events-message" data-track="no-markets-list">
        <div className="no-events-message__icon" />
        <div className="no-events-message__text">
          <p>{t('javascript.in_play.no_competitions')}</p>
        </div>
      </div>
    );
};

SportsTreeContainer.propTypes = {
  sportDimension: PropTypes.string.isRequired,
};

export default SportsTreeContainer;
