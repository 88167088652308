import { compose } from 'underscore';

import doFetchSport from './api';
import { filterEnabledComponents, filterNotEmptySections } from './sport_filters';

export const FETCH_SPORT_INIT = 'sportHome/FETCH.INIT';
export const FETCH_SPORT_COMPLETE = 'sportHome/FETCH.COMPLETE';
export const SET_ACTIVE_SECTION = 'sportHome/SET_ACTIVE_SECTION';
export const RESET_STATE = 'sportHome/RESET_STATE';

export const fetchSportInit = () => ({
  type: FETCH_SPORT_INIT,
});

export const fetchSportComplete = (sportId) => (sportData) => ({
  type: FETCH_SPORT_COMPLETE,
  ...sportData,
  sportId,
});

export const setActiveSection = (activeSectionLabel) => ({
  type: SET_ACTIVE_SECTION,
  activeSectionLabel,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const fetchSport = (sportId) => (dispatch) => {
  dispatch(fetchSportInit());

  doFetchSport(sportId)
    .then(filterEnabledComponents)
    .then(filterNotEmptySections)
    .then(compose(dispatch, fetchSportComplete(sportId)));
};

const initialState = {
  fetched: false,
  sections: [],
  activeSectionLabel: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SPORT_INIT:
    case RESET_STATE:
      return initialState;
    case FETCH_SPORT_COMPLETE:
      return {
        ...state,
        fetched: true,
        sections: action.sections,
        activeSectionLabel: action.activeSection,
        fetchedSportId: action.sportId,
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSectionLabel: action.activeSectionLabel,
      };
    default:
      return state;
  }
};
