import { useState } from 'react';
import PropTypes from 'prop-types';
import CompetitionsGroupView from './competitions_group_view';

const CompetitionsGroupContainer = ({ competitionsGroup, expanded }) => {
  const segregations = competitionsGroup.children;
  const firstSegregation = segregations[0] || { dimension_value: '' };

  const [
    activeSegregation,
    setActiveSegregation,
  ] = useState(firstSegregation.dimension_value);

  if (segregations.length === 0) return null;

  return (
    <CompetitionsGroupView
      competitionsGroup={competitionsGroup}
      expanded={expanded}
      segregations={segregations}
      activeSegregation={activeSegregation}
      setActiveSegregation={setActiveSegregation}
    />
  );
};

CompetitionsGroupContainer.propTypes = {
  competitionsGroup: PropTypes.instanceOf(Object).isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default CompetitionsGroupContainer;
