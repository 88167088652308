import { Segregation } from './segregation';

const SegregationContainer = (
  { segregations, activeSegregation },
) => segregations.map((segregation) => {
  if (activeSegregation !== segregation.dimension_value) return null;

  return (
    <Segregation segregation={segregation} />
  );
});

export default SegregationContainer;
