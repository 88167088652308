import PropTypes from 'prop-types';
import { ExpandableList } from 'bv-components';
import { SegregationsSelector } from './segregations_selector';
import { Segregations } from './segregations';

const CompetitionsGroupView = ({
  competitionsGroup,
  expanded,
  segregations,
  activeSegregation,
  setActiveSegregation,
}) => (
  <ExpandableList header={competitionsGroup.description} expanded={expanded}>
    <SegregationsSelector
      segregations={segregations}
      activeSegregation={activeSegregation}
      setActiveSegregation={setActiveSegregation}
    />
    <Segregations
      segregations={segregations}
      activeSegregation={activeSegregation}
    />
  </ExpandableList>
);

CompetitionsGroupView.propTypes = {
  competitionsGroup: PropTypes.instanceOf(Object).isRequired,
  expanded: PropTypes.bool.isRequired,
  segregations: PropTypes.instanceOf(Array).isRequired,
  activeSegregation: PropTypes.string.isRequired,
  setActiveSegregation: PropTypes.func.isRequired,
};

export default CompetitionsGroupView;
