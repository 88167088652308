import PropTypes from 'prop-types';
import { last } from 'underscore';
import { SportsTree } from './sports_tree';

const sportDimension = (filter) => last(filter.split('='));

const CompetitionsComponent = ({ filter }) => (
  <SportsTree
    sportDimension={sportDimension(filter)}
  />
);

CompetitionsComponent.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default CompetitionsComponent;
