import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { useFeature } from 'bv-hooks';

const BetFinderLauncher = () => {
  const [enabled, resolved] = useFeature('bet-finder');

  if (enabled && resolved) {
    return (
      <div className="bf-launcher">
        <div className="bf-launcher__content">
          <p>{t('javascript.bet_finder.slogan')}</p>
          <Button to="/sports/364/bet-finder" primary>{t('javascript.bet_finder.search')}</Button>
        </div>
      </div>
    );
  }

  return null;
};

export default BetFinderLauncher;
