import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default (sportId, params = {}) => (
  getJSON(`/api/sportsbook/sports/${sportId}/tree`, {
    ...params,
    c: locale(),
  })
    .catch(() => [])
);
