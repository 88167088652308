import PropTypes from 'prop-types';
import SegregationsSelectorView from './segregations_selector_view';

const SegregationsSelectorContainer = ({
  segregations,
  activeSegregation,
  setActiveSegregation,
}) => {
  if (segregations.length === 1) return null;

  return (
    <SegregationsSelectorView
      segregations={segregations}
      activeSegregation={activeSegregation}
      setActiveSegregation={setActiveSegregation}
    />
  );
};

SegregationsSelectorContainer.propTypes = {
  segregations: PropTypes.instanceOf(Array).isRequired,
  activeSegregation: PropTypes.string.isRequired,
  setActiveSegregation: PropTypes.func.isRequired,
};

export default SegregationsSelectorContainer;
