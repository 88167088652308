import PropTypes from 'prop-types';

const SportBanner = ({ sportId }) => (
  <div className="sports-banner">
    <span className={`bvs-icon is-sport-${sportId} is-inverted`} />
  </div>
);

SportBanner.propTypes = {
  sportId: PropTypes.string.isRequired,
};

export default SportBanner;
