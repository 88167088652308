import PropTypes from 'prop-types';
import { ListRow } from 'bv-components';
import { linkLocalizer } from 'bv-helpers/locale';

const meetingUrl = (sportId, id) => linkLocalizer(
  `/sports/${sportId}/meetings/${id}/all`,
);

const Meeting = ({ description, sportId, id }) => (
  <ListRow href={meetingUrl(sportId, id)} label={description} hasArrow />
);

Meeting.propTypes = {
  sportId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
};

export default Meeting;
