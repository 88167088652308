import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Icon, ListRow } from 'bv-components';

const Event = ({
  meetingId, id, description, eventInformation, hasStream,
}) => {
  const { sportId } = useParams();

  return (
    <ListRow to={`/sports/${sportId}/meetings/${meetingId}/events/${id}`}>
      <span>{description}</span>
      {eventInformation && (
        <span className="info">{eventInformation}</span>
      )}
      {hasStream && <Icon id="live-streaming" className="has_stream" />}
    </ListRow>
  );
};

Event.propTypes = {
  id: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  eventInformation: PropTypes.string.isRequired,
  hasStream: PropTypes.bool.isRequired,
};

export default Event;
